<template>
  <div class="d-flex">
    <div v-if="headerIcon" class="dialog-title mr-4">
      <component :is="iconComponent" color="#888" :size="26" />
    </div>
    <div class="d-flex align-center pb-1">
      <block-text :text="title" color="secondary" type="title" />
    </div>
    <v-spacer></v-spacer>
    <div class="cursor-pointer" @click="closeDialog"><ph-x :size="22" /></div>
  </div>
</template>

<script>
import { PhX } from "phosphor-vue";
export default {
  components: {
    PhX,
  },
  data() {
    return {
      iconComponent: null,
    };
  },
  props: {
    passedData: {
      default: null,
    },
    title: {
      default: "",
    },
    headerIcon: {
      default: null,
    },
  },
  async mounted() {
    this.componentLoader();
  },
  watch: {
    headerIcon() {
      this.componentLoader();
    },
  },
  methods: {
    closeDialog() {
      this.$eventHub.$emit("toggleDialog");
    },
    async componentLoader() {
      if (this.headerIcon) {
        let res = await (() => import("phosphor-vue"))``;
        this.iconComponent = res[this.headerIcon];
      }
    },
  },
};
</script>
